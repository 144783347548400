import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import GLOBAL_CONSTANTS from '@/constants/globals.json';
import LayoutLogo from '@/commons/components/LayoutLogo';
import LoadingPrompts from '@/commons/components/LoadingPrompts';

import Content from './Content';
import LoginTabs from './LoginTabs';
import OTPLoginForm from './OTPLoginForm';
import styles from './styles.module.css';

const LOGIN_FLOW_MAPPING = {
	login_tabs: LoginTabs,
	otp_form: OTPLoginForm,
	loading_prompts: LoadingPrompts,
};

const redirectToApp = () => {
	window.open(`${GLOBAL_CONSTANTS.APP_SIDE_URL}/login/`, '_self');
};

function Login() {
	const { t } = useTranslation(['login']);

	const translateKey = 'login:loginFooter';

	const [mode, setMode] = useState('login_tabs');
	const [mobileNumber, setMobileNumber] = useState({});
	const [otpId, setOtpId] = useState('');

	const componentProps = {
		login_tabs: {
			setMode,
			setMobileNumber,
			setOtpId,
			mobileNumber,
		},
		otp_form: {
			otpId,
			mobileNumber,
			setMode,
		},
		loading_prompts: {
			type: 'login',
		},
	};

	const Component = LOGIN_FLOW_MAPPING[mode] || null;

	return (
		<div
			className={styles.authentication_layout}
			style={{
				backgroundImage: `url(${GLOBAL_CONSTANTS.image_urls.world_map})`,
			}}
		>
			<LayoutLogo />

			<div className={styles.main_content}>
				<div className={styles.signup_content}>
					<Content />
				</div>

				<div className={styles.card_container}>
					<div className={styles.card}>
						{Component && (
							<Component key={mode} {...(componentProps[mode] || {})} />
						)}
						{mode !== 'loading_prompts' ? (
							<p className={styles.footer_txt}>
								{t(`${translateKey}_app_side_text1`)}
								<span
									className={styles.cta}
									onClick={redirectToApp}
									role="presentation"
								>
									{t(`${translateKey}_app_side_text2`)}
								</span>
								{t(`${translateKey}_app_side_text3`)}
							</p>
						) : null}
					</div>
				</div>
			</div>

			{/* <LayoutHelp /> */}
		</div>
	);
}

export default Login;
