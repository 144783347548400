import { Toast } from '@cogoport/components';
import { useTranslation } from 'next-i18next';

import { getApiErrorString } from '@cogoport/front/utils';
import { useRouter } from '@/temp/next';
import useRequest from '@/temp/request/useRequest';
import setCookieAndRedirect from '@/utils/setCookieAndRedirect';

const getFormattedPayload = ({ mobileNumber, otpId, otpValue }) => ({
	id: otpId,
	mobile_otp: otpValue,
	mobile_number: mobileNumber?.number,
	mobile_country_code: mobileNumber?.country_code,
	auth_scope: 'partner',
	platform: 'partner',
});

const useLoginOtpAuthentication = (props) => {
	const {
		mobileNumber = {},
		otpId = '',
		otpValue = '',
		setMode = () => {},
	} = props;

	const { query = '' } = useRouter();

	const { t } = useTranslation(['common']);

	const { loading: loginLoading, trigger } = useRequest(
		'post',
		false,
		'partner',
	)('user/login_user_with_mobile');

	const onLoginWithOtp = async () => {
		try {
			const payload = getFormattedPayload({ mobileNumber, otpId, otpValue });

			const response = await trigger({
				data: payload,
			});

			const { token } = response.data || {};

			let redirectPath;

			if (query.redirectPath) {
				redirectPath = `${query.redirectPath}`;
			}

			setMode('loading_prompts');

			setCookieAndRedirect(token, {}, redirectPath);
		} catch (err) {
			Toast.error(
				getApiErrorString(err?.response?.data) || t('common:loginField_error'),
			);
		}
	};

	return { onLoginWithOtp, loginLoading };
};

export default useLoginOtpAuthentication;
