import { Button } from '@cogoport/components';
import { Link, useRouter } from '@/temp/next';
import { getCookie } from '@cogoport/utils';
import {
	IcMArrowRight,
	IcAFinancial,
	IcANetwork,
	IcAProgram,
	IcASurfaceFttRail,
} from '@cogoport/icons-react';
import { useTranslation } from 'next-i18next';

import GLOBAL_CONSTANTS from '@/constants/globals.json';
import styles from './styles.module.css';

const ICON_MAPPING = {
	logistic: {
		title: 'End to End Logistics',
		icon: <IcASurfaceFttRail width={72} height={60} />,
	},
	finance: {
		title: 'Finance',
		icon: <IcAFinancial width={72} height={60} />,
	},
	trade: {
		title: 'Trade Management',
		icon: <IcAProgram width={72} height={60} />,
	},
	network: {
		title: 'Global Network',
		icon: <IcANetwork width={72} height={60} />,
	},
};

function Content() {
	const { t } = useTranslation(['login']);
	const { locale } = useRouter();

	let countryCode = '';
	if (typeof document !== 'undefined') {
		countryCode = getCookie('location');
	}

	return (
		<div className={styles.container}>
			<div>
				<div className={styles.title_container}>
					<h1 className={styles.title}>{t('login:login_content_title')}</h1>
					<div className={styles.line} />
				</div>

				<p className={styles.sub_title}>
					{t('login:login_content_desc')}{' '}
					<a
						href={GLOBAL_CONSTANTS.PUBLIC_PAGE_URL}
						target="_blank"
						rel="noreferrer"
						className={styles.know_more}
					>
						{t('login:leftPanel_texts_know_more')}
					</a>
				</p>

				{countryCode === 'IN' ? (
					<div style={{ width: 'fit-content' }}>
						<Link
							locale={locale}
							href="/schedule-demo"
							rel="noreferrer nofollow"
						>
							<Button themeType="secondary" style={{ marginTop: '24px' }}>
								{t('common:schedule_demo_text')}

								<IcMArrowRight />
							</Button>
						</Link>
					</div>
				) : null}
			</div>

			<div className={styles.icon_row}>
				{Object.keys(ICON_MAPPING).map((ele) => {
					const { title, icon } = ICON_MAPPING[ele] || {};
					return (
						<div key={ele} className={styles.icon_container}>
							<div className={styles.icon}>{icon}</div>
							<p>{title}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Content;
