import { Tabs, TabPanel } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useRouter } from '@/temp/next';
import EmailLoginForm from '../EmailLoginForm';
import MobileLoginForm from '../MobileLoginForm';

import styles from './styles.module.css';

function LoginTabs({
	setMode = () => {},
	setMobileNumber = () => {},
	setOtpId = () => {},
	mobileNumber = {},
}) {
	const { query } = useRouter();

	const { redirectPath = '', value = '' } = query;

	const [, source] = redirectPath.split('?source=');

	const { t } = useTranslation(['common', 'login']);
	const translationKey = 'login:loginField';

	const [activeTab, setActiveTab] = useState(source || 'email');

	return (
		<>
			<h1 className={styles.card_heading}>{t(`${translationKey}_title`)}</h1>

			<Tabs
				activeTab={activeTab}
				fullWidth
				themeType="secondary"
				onChange={setActiveTab}
				style={{ width: '100%' }}
			>
				<TabPanel name="email" title={t(`${translationKey}_tab_email`)}>
					<EmailLoginForm
						setMode={setMode}
						{...(source === 'email' && { value })}
						query={query}
					/>
				</TabPanel>

				<TabPanel name="mobile" title={t(`${translationKey}_tab_mobile`)}>
					<MobileLoginForm
						setMode={setMode}
						setMobileNumber={setMobileNumber}
						setOtpId={setOtpId}
						mobileNumber={mobileNumber}
						{...(source === 'mobile' && { value })}
					/>
				</TabPanel>
			</Tabs>
		</>
	);
}

export default LoginTabs;
