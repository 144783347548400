import { Button } from '@cogoport/components';
import { isEmpty } from '@cogoport/utils';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { useForm, MobileNumberSelectController } from '@/commons/form';
import { Link } from '@/temp/next';
import useLoginMobileAuthentication from '../hooks/useLoginMobileAuthentication';
import useMobileLoginForm from '../hooks/useMobileLoginForm';

import styles from './styles.module.css';

function MobileLoginForm({
	setMode = () => {},
	setMobileNumber = () => {},
	setOtpId = () => {},
	mobileNumber = {},
	value = '',
}) {
	const { t } = useTranslation(['login']);
	const translationKey = 'login:loginField';

	const { onSendOtp = () => {}, otpLoading = false } =
		useLoginMobileAuthentication({
			setMode,
			setMobileNumber,
			setOtpId,
			mobileNumber,
		});

	const { handleSubmit, control, watch, setValue } = useForm();

	const formValues = watch();

	const { customError = '', onOtpApiCall = () => {} } = useMobileLoginForm({
		formValues,
		onSendOtp,
		setValue,
		t,
	});

	useEffect(() => {
		let mobileValueObj = {};

		try {
			mobileValueObj = JSON.parse(value);
		} catch (error) {
			console.error('Invalid JSON:', error);
		}

		if (isEmpty(mobileValueObj)) {
			return;
		}

		const { mobile_country_code = '', mobile_number = '' } =
			mobileValueObj || {};

		setValue('mobile_number', {
			country_code: `+${mobile_country_code.trim()}`,
			number: mobile_number,
		});
	}, [setValue, value]);

	return (
		<form
			className={styles.form_container}
			onSubmit={handleSubmit(onOtpApiCall)}
		>
			<div className={styles.label}>Mobile Number</div>
			<MobileNumberSelectController
				control={control}
				name="mobile_number"
				placeholder={t(`${translationKey}_mobile_placeholder`)}
				rules={{ required: t(`${translationKey}_mobile_error`) }}
			/>
			<div className={styles.errors}>{customError || ''}</div>

			<Button
				loading={otpLoading}
				className={styles.submit_button}
				type="submit"
				size="lg"
			>
				{t(`${translationKey}_submit_otp_button`)}
			</Button>

			<div className={styles.links}>
				<Link href="/signup">{t('login:loginFooter_signup')}</Link>
			</div>
		</form>
	);
}

export default MobileLoginForm;
