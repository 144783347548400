import { Button } from '@cogoport/components';
import { isEmpty } from '@cogoport/utils';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import Link from 'next/link';
import { InputController, useForm } from '@/commons/form';
import useLoginEmailAuthentication from '../hooks/useLoginEmailAuthentication';
import TogglePasswordView from '../common/TogglePasswordView';

import styles from './styles.module.css';

const getRedirectLink = ({ query = {} }) => {
	const { redirectPath = '' } = query;

	if (isEmpty(query) || redirectPath === '/') {
		return '/forgot-password';
	}

	return Object.entries(query).reduce((acc, [key, value], index) => {
		if (!index) {
			return `${acc}?${key}=${value}`;
		}

		return `${acc}&${key}=${value}`;
	}, '/forgot-password');
};

function EmailLoginForm({ setMode = () => {}, value = '', query = {} }) {
	const { t } = useTranslation(['login']);
	const translationKey = 'login:loginField';

	const [showPassword, setShowPassword] = useState(false);

	const {
		handleSubmit,
		formState: { errors },
		control,
		setError,
	} = useForm({
		defaultValues: {
			email: value || '',
			password: '',
		},
	});

	const { onSubmit = () => {}, loginLoading = false } =
		useLoginEmailAuthentication({ setMode, t, setError });

	return (
		<form className={styles.form_container} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.label}>{t(`${translationKey}_email_label`)}</div>
			<InputController
				control={control}
				name="email"
				type="email"
				placeholder={t(`${translationKey}_email_placeholder`)}
				rules={{ required: t(`${translationKey}_email_error`) }}
			/>
			<span className={styles.errors}>{errors?.email?.message || ' '}</span>

			<div className={styles.label}>
				{t(`${translationKey}_password_label`)}
			</div>
			<InputController
				control={control}
				name="password"
				type={showPassword ? 'text' : 'password'}
				suffix={
					<TogglePasswordView
						showPassword={showPassword}
						setShowPassword={setShowPassword}
					/>
				}
				placeholder={t(`${translationKey}_password_placeholder`)}
				rules={{ required: t(`${translationKey}_password_error`) }}
			/>
			<span className={styles.errors}>{errors?.password?.message || ' '}</span>

			<Button
				loading={loginLoading}
				className={styles.submit_button}
				type="submit"
				size="lg"
			>
				{t(`${translationKey}_submit_button`)}
			</Button>

			<div className={styles.links}>
				<Link href={getRedirectLink({ query })}>
					{t('login:loginFooter_forgot_password')}
				</Link>
				<Link href="/signup">{t('login:loginFooter_signup')}</Link>
			</div>
		</form>
	);
}

export default EmailLoginForm;
