import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Login from '@/components/Login';

export async function getServerSideProps({ locale }) {
	const translated = await serverSideTranslations(locale, [
		'common',
		'login',
		'signup',
	]);
	return {
		props: {
			...translated,
			head: {
				title: 'Cogoport Partner | Login',
			},
			hideBG: true,
		},
	};
}

export default Login;
