import { Toast } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import showErrorsInToast from '@/utils/showErrorsInToast';

import useRequest from '@/temp/request/useRequest';

const useLoginMobileAuthentication = ({
	setMode = () => {},
	setMobileNumber = () => {},
	setOtpId = () => {},
	mobileNumber = {},
}) => {
	const { t } = useTranslation(['login']);

	const { loading: otpLoading, trigger } = useRequest(
		'post',
		false,
		'partner',
	)('/send_login_otp');

	const onSendOtp = async (values, e) => {
		e.preventDefault();
		try {
			const response = await trigger({
				data: {
					mobile_number: values?.mobile_number?.number,
					mobile_country_code: values?.mobile_number?.country_code,
					platform: 'partner',
				},
			});

			setOtpId(response?.data?.id);

			setMobileNumber(values?.mobile_number);

			setMode('otp_form');
		} catch (err) {
			showErrorsInToast(err?.data);
		}
	};

	const resendOtp = async ({ timer = {} }) => {
		try {
			const response = await trigger({
				data: {
					mobile_number: mobileNumber?.number,
					mobile_country_code: mobileNumber?.country_code,
					platform: 'partner',
				},
			});

			setOtpId(response?.data?.id);

			Toast.success(t('login:loginOtp_resendOtp_success'));

			timer?.restart?.();
		} catch (err) {
			showErrorsInToast(err?.data);
		}
	};

	return { onSendOtp, otpLoading, resendOtp };
};

export default useLoginMobileAuthentication;
